import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import {patterns} from '../../shared/helpers/patterns.helper';
import { LoginService } from '../services/login.service';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss']
})
export class PasswordComponent implements OnInit {

  lembrente
  hide = true;
  passwordHide = true;

  setPasswordForm: FormGroup;
  public submitted: boolean = false;
  public btnrecupera: boolean = false;
  constructor(
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private router: Router,
    private loginService: LoginService,
    private toastr: ToastrService
  ) {
    this.setPasswordForm = this.fb.group({});
  }

  ngOnInit() {
    this.setPasswordForm = this.fb.group({
      password1: ['', Validators.compose([Validators.required])],
      password: ['', Validators.compose([Validators.required])]
    });
  }

  submit(){
    this.spinner.show();
    this.submitted = true;
    if(this.setPasswordForm.valid){
      this.spinner.hide()
      this.toastr.success(`Senha foi trocada com sucesso..`)
      this.btnrecupera = true;
    }else{
      this.spinner.hide()
      this.toastr.error('Preencha os campos inválidos')
    }
  }

  get password1(){
    return this.setPasswordForm.get('password1')
  }
  
  get password(){
    return this.setPasswordForm.get('password')
  }
}