<nav>
  <div class="d-flex justify-content-between">
    <div>
      <p>
        <img src="../../../assets/img/png/logoLinear.png" alt="">
      </p>
    </div>
    <div class="d-flex">
      <svg class="ml-3" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 12.5C0 5.87258 5.37258 0.5 12 0.5C18.6274 0.5 24 5.87258 24 12.5C24 19.1274 18.6274 24.5 12 24.5C5.37258 24.5 0 19.1274 0 12.5ZM13.2508 19.5557V13.0271H15.053L15.2918 10.7773H13.2508L13.2538 9.65125C13.2538 9.06447 13.3096 8.75006 14.1524 8.75006H15.279V6.5H13.4766C11.3116 6.5 10.5495 7.5914 10.5495 9.42678V10.7776H9.2V13.0274H10.5495V19.5557H13.2508Z" fill="url(#paint0_linear_5381_22657)"/>
        <defs>
        <linearGradient id="paint0_linear_5381_22657" x1="13.1827" y1="-8.02245" x2="13.6532" y2="24.4935" gradientUnits="userSpaceOnUse">
        <stop offset="0.15022" stop-color="#26F0F1"/>
        <stop offset="0.938051" stop-color="#19B5CC"/>
        </linearGradient>
        </defs>
      </svg>

      <svg class="ml-3" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 12.5C0 5.87258 5.37258 0.5 12 0.5C18.6274 0.5 24 5.87258 24 12.5C24 19.1274 18.6274 24.5 12 24.5C5.37258 24.5 0 19.1274 0 12.5ZM8.48034 10.4389H5.76087V18.6098H8.48034V10.4389ZM8.65928 7.91135C8.64163 7.1102 8.06873 6.5 7.13842 6.5C6.20811 6.5 5.5999 7.1102 5.5999 7.91135C5.5999 8.6959 6.19013 9.32367 7.10312 9.32367H7.1205C8.06873 9.32367 8.65928 8.6959 8.65928 7.91135ZM18.2879 13.9248C18.2879 11.4151 16.9463 10.247 15.1568 10.247C13.7129 10.247 13.0665 11.0401 12.7055 11.5965V10.4391H9.98575C10.0216 11.2058 9.98575 18.61 9.98575 18.61H12.7055V14.0467C12.7055 13.8025 12.7232 13.5589 12.7951 13.3841C12.9916 12.8962 13.4391 12.3911 14.1904 12.3911C15.1749 12.3911 15.5684 13.1403 15.5684 14.2384V18.6098H18.2878L18.2879 13.9248Z" fill="url(#paint0_linear_5381_22651)"/>
        <defs>
        <linearGradient id="paint0_linear_5381_22651" x1="13.1827" y1="-8.02245" x2="13.6532" y2="24.4935" gradientUnits="userSpaceOnUse">
        <stop offset="0.15022" stop-color="#26F0F1"/>
        <stop offset="0.938051" stop-color="#19B5CC"/>
        </linearGradient>
        </defs>
      </svg>

      <svg class="ml-3" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 12.5C0 5.87258 5.37258 0.5 12 0.5C18.6274 0.5 24 5.87258 24 12.5C24 19.1274 18.6274 24.5 12 24.5C5.37258 24.5 0 19.1274 0 12.5ZM12.0006 6.1C10.2624 6.1 10.0443 6.1076 9.36164 6.13867C8.6803 6.16987 8.21523 6.27773 7.80829 6.436C7.38735 6.59947 7.03028 6.81814 6.67454 7.17401C6.31854 7.52975 6.09987 7.88682 5.93587 8.30762C5.7772 8.71469 5.6692 9.1799 5.63853 9.86097C5.608 10.5436 5.6 10.7619 5.6 12.5001C5.6 14.2382 5.60773 14.4557 5.63867 15.1384C5.67 15.8197 5.77787 16.2848 5.936 16.6917C6.0996 17.1126 6.31827 17.4697 6.67414 17.8255C7.02975 18.1815 7.38682 18.4007 7.80749 18.5641C8.21469 18.7224 8.6799 18.8303 9.36111 18.8615C10.0438 18.8925 10.2618 18.9001 11.9998 18.9001C13.7381 18.9001 13.9556 18.8925 14.6382 18.8615C15.3196 18.8303 15.7852 18.7224 16.1924 18.5641C16.6132 18.4007 16.9697 18.1815 17.3253 17.8255C17.6813 17.4697 17.9 17.1126 18.064 16.6918C18.2213 16.2848 18.3293 15.8196 18.3613 15.1385C18.392 14.4558 18.4 14.2382 18.4 12.5001C18.4 10.7619 18.392 10.5438 18.3613 9.86111C18.3293 9.17977 18.2213 8.71469 18.064 8.30776C17.9 7.88682 17.6813 7.52975 17.3253 7.17401C16.9693 6.81801 16.6133 6.59934 16.192 6.436C15.784 6.27773 15.3186 6.16987 14.6373 6.13867C13.9546 6.1076 13.7373 6.1 11.9986 6.1H12.0006Z" fill="url(#paint0_linear_5381_22659)"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.4265 7.25335C11.5969 7.25308 11.787 7.25335 12.0006 7.25335C13.7094 7.25335 13.912 7.25948 14.5868 7.29015C15.2108 7.31868 15.5494 7.42295 15.775 7.51055C16.0737 7.62655 16.2866 7.76522 16.5105 7.98922C16.7345 8.21322 16.8732 8.42656 16.9895 8.72523C17.0771 8.95056 17.1815 9.28923 17.2099 9.91324C17.2405 10.5879 17.2472 10.7906 17.2472 12.4986C17.2472 14.2066 17.2405 14.4093 17.2099 15.084C17.1813 15.708 17.0771 16.0466 16.9895 16.272C16.8735 16.5706 16.7345 16.7833 16.5105 17.0072C16.2865 17.2312 16.0738 17.3699 15.775 17.4859C15.5497 17.5739 15.2108 17.6779 14.5868 17.7064C13.9121 17.7371 13.7094 17.7437 12.0006 17.7437C10.2916 17.7437 10.0891 17.7371 9.41444 17.7064C8.79043 17.6776 8.45176 17.5733 8.22603 17.4857C7.92736 17.3697 7.71402 17.231 7.49002 17.007C7.26602 16.783 7.12735 16.5702 7.01108 16.2714C6.92348 16.0461 6.81908 15.7074 6.79068 15.0834C6.76001 14.4088 6.75388 14.2061 6.75388 12.497C6.75388 10.7879 6.76001 10.5863 6.79068 9.91164C6.81921 9.28763 6.92348 8.94896 7.01108 8.72336C7.12708 8.42469 7.26602 8.21136 7.49002 7.98735C7.71402 7.76335 7.92736 7.62468 8.22603 7.50841C8.45163 7.42041 8.79043 7.31641 9.41444 7.28775C10.0048 7.26108 10.2336 7.25308 11.4265 7.25175V7.25335ZM15.4169 8.31602C14.9929 8.31602 14.6489 8.65963 14.6489 9.08376C14.6489 9.50777 14.9929 9.85177 15.4169 9.85177C15.8409 9.85177 16.1849 9.50777 16.1849 9.08376C16.1849 8.65976 15.8409 8.31602 15.4169 8.31602ZM12.0006 9.21337C10.1855 9.21337 8.7139 10.685 8.7139 12.5001C8.7139 14.3152 10.1855 15.7861 12.0006 15.7861C13.8157 15.7861 15.2868 14.3152 15.2868 12.5001C15.2868 10.685 13.8157 9.21337 12.0006 9.21337Z" fill="url(#paint1_linear_5381_22659)"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0006 10.3667C13.1787 10.3667 14.134 11.3218 14.134 12.5001C14.134 13.6782 13.1787 14.6334 12.0006 14.6334C10.8223 14.6334 9.86724 13.6782 9.86724 12.5001C9.86724 11.3218 10.8223 10.3667 12.0006 10.3667Z" fill="url(#paint2_linear_5381_22659)"/>
        <defs>
        <linearGradient id="paint0_linear_5381_22659" x1="13.1827" y1="-8.02245" x2="13.6532" y2="24.4935" gradientUnits="userSpaceOnUse">
        <stop offset="0.15022" stop-color="#26F0F1"/>
        <stop offset="0.938051" stop-color="#19B5CC"/>
        </linearGradient>
        <linearGradient id="paint1_linear_5381_22659" x1="13.1827" y1="-8.02245" x2="13.6532" y2="24.4935" gradientUnits="userSpaceOnUse">
        <stop offset="0.15022" stop-color="#26F0F1"/>
        <stop offset="0.938051" stop-color="#19B5CC"/>
        </linearGradient>
        <linearGradient id="paint2_linear_5381_22659" x1="13.1827" y1="-8.02245" x2="13.6532" y2="24.4935" gradientUnits="userSpaceOnUse">
        <stop offset="0.15022" stop-color="#26F0F1"/>
        <stop offset="0.938051" stop-color="#19B5CC"/>
        </linearGradient>
        </defs>
      </svg>
      <h5 class="ml-4">doctorhealthbr.com.br</h5>
    </div>
  </div>
</nav>
<div class="container-fluid d-flex justify-content-center align-items-center">
  <form [formGroup]="setPasswordForm" (ngSubmit)="submit()" class="login form-row">
    
    <div class="card" style="width: 50rem;">
      <div class="card-body">
        <div class="col-12 d-flex justify-content-center logo">
          <img *ngIf="btnrecupera != true" src="../../../assets/img/png/logoExterna.png" alt="">
          <svg *ngIf="btnrecupera == true" width="173" height="173" viewBox="0 0 173 173" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M147.665 25.335C131.327 8.99769 109.605 0 86.5 0C63.3947 0 41.6724 8.99769 25.335 25.335C8.99735 41.6727 0 63.395 0 86.5C0 109.605 8.99735 131.327 25.335 147.665C41.6727 164.002 63.3947 173 86.5 173C109.605 173 131.327 164.002 147.665 147.665C164.002 131.327 173 109.605 173 86.5C173 63.395 164.002 41.6731 147.665 25.335ZM86.5 162.863C44.3931 162.863 10.1367 128.607 10.1367 86.5C10.1367 44.3931 44.3931 10.1367 86.5 10.1367C128.607 10.1367 162.863 44.3931 162.863 86.5C162.863 128.607 128.607 162.863 86.5 162.863Z" fill="#18345A"/>
            <path d="M131.937 74.887L95.9074 110.854L85.5886 121.141C80.1229 126.575 71.3119 126.559 65.862 121.11L42.0989 97.2836C36.539 91.7237 36.5547 82.6928 42.1303 77.1172C44.9103 74.3373 48.554 72.9552 52.1978 72.9552C55.8573 72.9709 59.5011 74.353 62.2967 77.1486L65.7677 80.6511C71.202 86.0853 80.0444 86.101 85.4786 80.6668L94.7608 71.416L111.66 54.5478C117.236 49.0036 126.251 49.0036 131.827 54.5792L131.968 54.7206C137.544 60.2962 137.528 69.3271 131.937 74.887Z" fill="#18345A"/>
          </svg>
             
        </div>
        <div class="pt-3 pb-3">
          <h5 *ngIf="btnrecupera != true" class="d-flex justify-content-center">Definir nova senha</h5>
          <h5 *ngIf="btnrecupera == true" class="d-flex justify-content-center">Nova senha definida com sucesso!</h5>
        </div>

        <p *ngIf="btnrecupera == true" class="text-center pt-3 pb-3">Agora você pode voltar a acessar todos os benefícios
          do Doctor Health! </p>

        
        <div *ngIf="btnrecupera != true" class="col-12 form-group mb-0">
          <label>Senha</label>
        </div>

        <div  *ngIf="btnrecupera != true" class="col-12 form-group d-flex m-0" >
          <input [type]="hide ? 'password' : 'text'" placeholder="Senha" formControlName="password" id="password" class="form-control" style="border-radius: 0.25rem
           0 0 0.25rem !important;" 
             [ngClass]="{
              'is-invalid': submitted && password.invalid,
              'is-valid': submitted && password.valid
            }">
          <div class="input-group-append">
            <span class="input-group-text" (click)="hide = !hide">
              <i class="material-icons">{{hide ? 'visibility_off' : 'visibility'}}</i>
            </span>
          </div>         
        </div>
        <div *ngIf="submitted && password.invalid" class="alert alert-danger">
          <div *ngIf="password.errors.required">
            Senha é obrigatório!
          </div>
        </div>

        <div class="col-12 form-group mb-0" *ngIf="btnrecupera != true">
          <label>Confirmar nova senha</label>
        </div>

        <div class="col-12 form-group d-flex m-0" *ngIf="btnrecupera != true" >
          <input [type]="passwordHide ? 'password' : 'text'" placeholder="Senha" formControlName="password1" id="password1" class="form-control" style="border-radius: 0.25rem
           0 0 0.25rem !important;" 
             [ngClass]="{
              'is-invalid': submitted && password1.invalid,
              'is-valid': submitted && password1.valid
            }">
          <div class="input-group-append">
          <span class="input-group-text" (click)="passwordHide = !passwordHide">
            <i class="material-icons">{{passwordHide ? 'visibility_off' : 'visibility'}}</i>
          </span>
          </div>         
        </div>
        <div *ngIf="submitted && password1.invalid" class="alert alert-danger">
          <div *ngIf="password1.errors.required">
            Senha é obrigatório!
          </div>
        </div>
        <div class="col-12 form-group button pt-3" *ngIf="btnrecupera != true">
            <button type="submit" class="btn btn-warning btn-sm btn-block p-2">Recuperar senha</button>
        </div>
        <div class="col-12 form-group button pt-3" *ngIf="btnrecupera == true">
          <button type="submit" class="btn btn-warning btn-sm btn-block p-2">Entrar</button>
      </div>
        <div class="col-12 pb-3 d-flex justify-content-center ">
          <div class="col-auto pb-3 pt-2">
            <a class="esquece" routerLink="../login">Voltar para a tela de login</a>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
