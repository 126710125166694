import { LoginService } from './../../../core/services/login.service';
import { Router } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  controllerSideBar = true
  extensionSideBar = true
  @Input() itens
  classApplied = false;
  classAppliedExtension = false;

  constructor(
    private loginService: LoginService
  ) { }

  ngOnInit(): void {
  }

  logout(){
    this.loginService.logout()
  }

  btnMenu() {
    this.controllerSideBar = !this.controllerSideBar;
    this.extensionSideBar = !this.extensionSideBar;
  }

  toggleExtensionClass() {
    this.classAppliedExtension = !this.classAppliedExtension;
  }

  toggleClass() {
    this.classApplied = !this.classApplied;
  }

}
