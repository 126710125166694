import { Router } from '@angular/router';
import {Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {patterns} from '../../shared/helpers/patterns.helper';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  hide = true;
  loginForm: FormGroup;
  public submitted: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private router: Router,
    private toastr: ToastrService
  ) {
    this.loginForm = this.formBuilder.group({});
  }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email: ['doctor_health@adm.com', Validators.compose([Validators.required, Validators.pattern(patterns.email)])],
      password: ["hu07m8&267#21@bbee", [Validators.required]]
    });
  }

  submit(){
    this.spinner.show();
    this.submitted = true;
    if(this.loginForm.valid){
      this.spinner.hide()
      this.router.navigate(['/admin'])
    }else{
      this.spinner.hide()
      this.toastr.error('Preencha os campos inválidos')
    }
  }

  get email(){
    return this.loginForm.get('email')
  }

  get password(){
    return this.loginForm.get('password')
  }
}
