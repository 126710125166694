import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import {patterns} from '../../shared/helpers/patterns.helper';
import { LoginService } from '../services/login.service';

@Component({
  selector: 'app-password-recover',
  templateUrl: './password-recover.component.html',
  styleUrls: ['./password-recover.component.scss']
})
export class PasswordRecoverComponent implements OnInit {

  forgotPasswordForm: FormGroup;
  public submitted: boolean = false;
  public btnrecupera: boolean = false;
  constructor(
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private router: Router,
    private loginService: LoginService,
    private toastr: ToastrService
  ) {
    this.forgotPasswordForm = this.fb.group({});
  }

  ngOnInit() {
    this.forgotPasswordForm = this.fb.group({
      email: ['', Validators.compose([Validators.required, Validators.pattern(patterns.email)])]
    });
  }

  submit(){
    this.toastr.success(`Sucesso!Foi encaminhando código para o seu email..`)
    this.router.navigate(['/troca-senha'])

  }

  get email(){
    return this.forgotPasswordForm.get('email')
  }
}