import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminGuard } from './core/guards/admin.guard';
import { LoginGuard } from './core/guards/login.guard';
import { LoginComponent} from './core/login/login.component';
import { PasswordRecoverComponent} from './core/password-recover/password-recover.component';
import { PasswordComponent } from './core/password/password.component';
import { RegisterComponent } from './core/register/register.component';
import { SetPasswordComponent } from './core/set-password/set-password.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'login' },
  { path: 'login', component: LoginComponent},
  { path: 'recuperar-senha', component: PasswordRecoverComponent, canActivate: [LoginGuard] },
  { path: 'troca-senha', component: SetPasswordComponent, canActivate: [LoginGuard] },
  { path: 'senha', component: PasswordComponent, canActivate: [LoginGuard] },
  { path: 'cadastro', component: RegisterComponent, canActivate: [LoginGuard] },
  { path: 'admin', loadChildren: () => import('./adm/adm.module').then((m) => m.AdmModule) }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
