import { Router } from '@angular/router';
import {Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {patterns} from '../../shared/helpers/patterns.helper';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  public hide = true;
  public loginForm: FormGroup;
  public submitted: boolean = false;
  public isVisibleMedico: boolean = false;
  public isVisibleMedicoParte2: boolean = false;
  public isVisibleClinica: boolean = false;
  public isVisibleClinicaParte2: boolean = false;
  public isVisibleAtendente: boolean = false;
  public isVisibleTipo: boolean = false;
  public isVisibleSenha: boolean = false;
  public isVisibleSucesso: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private router: Router,
    private toastr: ToastrService
  ) {
    this.loginForm = this.formBuilder.group({});
  }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      tipo: [null],
      password: ["hu07m8&267#21@bbee", [Validators.required]]
    });
  }

  submit(){
    this.spinner.show();
    this.submitted = true;
    if(this.loginForm.valid){
      this.spinner.hide()
      this.router.navigate(['/admin'])
    }else{
      this.toastr.error('Preencha os campos inválidos')
    }
  }

  btnTipo() {
   if(this.tipo.value == 3) {
    this.isVisibleAtendente = true;
    this.isVisibleTipo = true;
   } else if (this.tipo.value == 1) {
    this.isVisibleClinica = true;
    this.isVisibleTipo = true;
   } else if (this.tipo.value == 2) {
    this.isVisibleMedico = true;
    this.isVisibleTipo = true;
   } else {
    this.toastr.error('Preencha o campo Tipo')
   }
  }

  visibleClinica() {
    this.isVisibleClinicaParte2 = true 
    this.isVisibleClinica = false;
  }


  get tipo(){
    return this.loginForm.get('tipo')
  }

  get telefone(){
    return this.loginForm.get('telefone')
  }

  get cpf(){
    return this.loginForm.get('cpf')
  }

  get date(){
    return this.loginForm.get('date')
  }

  get name(){
    return this.loginForm.get('name')
  }

  get email(){
    return this.loginForm.get('email')
  }
}
